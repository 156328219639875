<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <nav-bar></nav-bar>
    <div  class="banner-spacing">
  <el-carousel arrow="always" height="auto" :autoplay="false">
    <el-carousel-item v-for="(item,index) in banner" :key="index" class="banner" v-lazy:background-image="{src: item.images}" @click="goTo(item.arguments)">
    
        <!-- <div class="banner-title">V8系列</div> -->
        <div  class="more pointer" @click="goTo(item.arguments)">了解更多 ></div>
     

    </el-carousel-item>
  </el-carousel>
    </div>
  <div class="flex-evenly">
    <div class="item-content" v-for="(item,index) in itemContent" :key="index"  v-lazy:background-image="{src: item.images}">
      <a class="item-content-a" @click="goTo(item.arguments)"></a>
      <!-- <img v-lazy=" item.images" :key="item.images" /> -->
      <!-- <div style="width:100px;height:100px;" v-lazy:background-image="{src: item.images}"></div> -->
      <div  class="item-content-specialty">{{item.specialty}}</div>
      <div  class="item-content-title">{{item.title}}</div>
      <div class="item-content-description">{{item.description}}</div>
      <div class="item-content-text">
        <span @click="goTo(item.arguments)" class="a_green">了解详情 ></span>
        <span class="a_green m-l-20"  @click="go3D(item.arguments)">3D展厅 ></span>
      </div>
      
    </div>
  </div>
 

    <!-- <swiper class="modellist_container" 
                         :navigation="true"  
                         :modules="modules" 
                         :slides-per-view="3" 
                         :slides-per-group="3" 
                     
                         :grid="{ rows:1,fill:'column' }">
         <swiper-slide v-for="item,key in 8" :key="key">
             <div style="background:blue;width:376px;height: 569px;margin:0 auto">{{item}}</div>
         </swiper-slide>
  </swiper> -->
  <div>
    <div class="story-title">关于星戟 </div>
    <div class="story-description">坚持服务至上的原则，致力于成为全国智能IDC全链路综合解决方案供应商。</div>
  </div>
  <div  class="flex-between container">
    <div class="story" story v-for="(item,index) in story" :key="index"  :style="{'background-image': `url(${item.images})`}" @click="go(item.arguments)">
      <div class="item-story-specialty">{{item.specialty}}</div>
      <div class="item-story-description">{{item.description}}</div>
      <div @click="go(item.arguments)" class="a_green">了解详情 ></div>
    </div>
  </div>
  <div class="story-title">星戟服务</div>
    <div class="story-description container">针对现阶段数据中心全链路建设服务周期内出现的诸多问题和市场痛点，以及社会各界对算力、存储力以及网络交换力等日益增长的刚性需求。</div>
    <div  class="flex-between container">
      <div class="product" v-for="(item,index) in product" :key="index" @click="go(item.arguments)">
        <img :src="require('../assets/images/home-page/product'+index+'.png')"  class="product-img"/>
        <div class="product-text">
          <div class="product-title">{{item.title}}</div>
          <div class="product-description">{{item.description}}</div>
          <div @click="go(item.arguments)" class="a_green">了解详情 ></div>
        </div>
      </div>
    </div>
    <div  class="flex-between container company-information-container">
      <div class="company-information" v-for="(item,index) in companyInformation" :key="index" @click="go(item.arguments)">
        <img :src="item.images" class="company-information-img"/>
        <div class="company-information-title">{{item.title}}</div>
      <div @click="go(item.arguments)" class="a_green">了解更多 ></div>
      </div>
    </div>
   <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import HelloWorld from '@/components/HelloWorld.vue'
import { Pagination, Navigation ,Autoplay } from "swiper";
import { Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";  // 所有的Swiper样式
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router';
export default {
  name: 'Home',
  components: {
    navBar,
    HelloWorld,
    Swiper,
    SwiperSlide,
    Footer,
  },

  setup() { 
    const router = useRouter()
    const useData = reactive({
      banner: [
        {
          images: require('../assets/images/v8/item0.jpg'),
          arguments:"v8"
        },
        {
          images: require('../assets/images/v2/item0.jpg'),
          arguments:"v2"
        },
        {
          images: require('../assets/images/v4/item0.jpg'),
          arguments:"v4"
        },
        {
          images: require('../assets/images/v6/item0.jpg'),
          arguments:"v6"
        },
        // {
        //   images: require('../assets/images/ad/item0.jpg'),
        //   arguments:"ad"
        // }      
      ],
      companyInformation: [
        {
          images: require('../assets/images/home-page/phone.png'),
          title: "电话咨询",
          arguments:"TelephoneCounseling"
        }, {
          images: require('../assets/images/home-page/serve.png'),
          title: "服务与支持",
          arguments:"ServiceSupport"
        }, {
          images: require('../assets/images/home-page/guide.png'),
          title: "使用指南",
          arguments:"UserManual"
        }
      ],
      product: [
        {
          title: "应用推广",
          description: "高可靠 利实施 便管理 ",
          arguments:"extension"

        },
        {
          title: "市场应用",
          description: "满足当前市场主流的部署需求。",
          arguments:"MarketApplication"
        },
        {
          title: "解决方案",
          description: "建设可持续和高弹性的边缘计算数据中心 ",
          arguments:"Solutions"
        },
      ],
      story: [
        {
          specialty: "产品特点",
          description: "绿色节能 运行高效 安全可靠",
          images: require('../assets/images/home-page/story1.png'),
          arguments:"Features"
        },
        {
          specialty: "性能至上",
          description: " 应用怎么选看这里",
          images: require('../assets/images/home-page/story2.png'),
          arguments:"solution"

        },

      ]
    })
    const autoplayOptions = {
  delay: 500,
  disableOnInteraction: false,
  loop: false,
  pauseOnMouseEnter: true,
  reverseDirection: true
    }
    const itemContent = [
      {
        specialty: "质量保障",
        title: "V8标准",
        description: "适用于普通数据中心和科技园区专用机房",
        images: require('../assets/images/v8/v8.png'),
        arguments:"v8"
      },
      {
        specialty: "自动部署",
        title: "AD模块",
        description: "为用户提供一站式的、端到端的高能效、高弹性的解决方案,适用于小型企业日常办公应用",
        images: require('../assets/images/ad/ad.png'),
        arguments:"ad"
      },
      {
        specialty: "质量保障",
        title: "V4标准",
        description: "适用于企业自有免维护机房和科技园区专用机房",
        images: require('../assets/images/v4/v4.png'),
        arguments:"v4"
      },
      {
        specialty: "质量保障",
        title: "V6标准",
        description: "适用于普通数据中心和科技园专用机房",
        images: require('../assets/images/v6/v6.png'),
        arguments:"v6"
      }
    ]
    const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
	  const go3D= (name) => {
      router.push({ path: '/sumgkee3d', query: { name } })
      }
    return {
      ...toRefs(useData),
      autoplayOptions,
      itemContent,
      modules: [Pagination, Navigation,Autoplay
      ],
      goTo,
      go,
	  go3D
  }
  }
}
</script>
<style scoped>
.banner-spacing{
  margin-bottom: 18px;
  margin-top: 92px;
}
.item-content-text{
  z-index: 4;position: relative;
}
</style>
