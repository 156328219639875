import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/Item',
    name: 'Item',
    component: () => import('../views/Item.vue')
  },
  {
    path: '/SeriesDetails',
    name: 'SeriesDetails',
    component: () => import('../views/SeriesDetails.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/ServiceSupport',
    name: 'ServiceSupport',
    component: () => import('../views/ServiceSupport.vue')
  },
  {
    path: '/Features',
    name: 'Features',
    component: () => import('../views/Features.vue')
  },
  {
    path: '/MarketApplication',
    name: 'MarketApplication',
    component: () => import('../views/MarketApplication.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution.vue')
  },
  {
    path: '/extension',
    name: 'extension',
    component: () => import('../views/extension.vue')
  },
  {
    path: '/Solutions',
    name: 'Solutions',
    component: () => import('../views/Solutions.vue')
  },
  {
    path: '/AfterSupport',
    name: 'AfterSupport',
    component: () => import('../views/AfterSupport.vue')
  },
  {
    path: '/Questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('../views/technology.vue')
  },
  {
    path: '/ValueAdded',
    name: 'ValueAdded',
    component: () => import('../views/ValueAdded.vue')
  },
  {
    path: '/TelephoneCounseling',
    name: 'TelephoneCounseling',
    component: () => import('../views/TelephoneCounseling.vue')
  },
  {
    path: '/UserManual',
    name: 'UserManual',
    component: () => import('../views/UserManual.vue')
  },
  {
    path: '/sumgkee3d',
    name: 'sumgkee3d',
    component: () => import('../views/sumgkee3d.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

export default router
