<template>
     <div  class="footer-content">
      <div class="flex-between container">
        <div>
          <div>联系电话：010-81333426 </div>
          <div>联系地址：北京市海淀区紫雀路33号院3号楼联动骏一国际创科中心四层3438</div>
        </div>
        <div>
          <div>copyright © 2020-2023 北京星戟技术有限公司 版权所有</div>
          <div>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037627" target="_blank" rel="noopener noreferrer">
            京公网安备 11010802037627号
            </a>
          <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021031748号</a>
          </div>
        </div>
      </div>
    </div>
</template>