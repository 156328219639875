import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem.js'
import VueLazyload from 'vue-lazyload'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
const  loadimage= require('./assets/images/default.gif')
const  errorimage= require('./assets/images/error.png')
import "./assets/global.css"
createApp(App).use(store).use(router).use(VueLazyload, {
  preLoad: 1,
  error: errorimage,
  loading: loadimage,
  attempt: 1
}).use(ElementPlus).mount('#app')
