<template>
  <router-view v-if="isRouterActive"></router-view>
</template>
<script>
import { ref, provide, nextTick } from 'vue'
export default {
  setup() { 
    const isRouterActive = ref(true)
provide('reload', () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
})
    return {
      isRouterActive,
    }
  }

}
</script>