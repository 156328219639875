<template>
    <div class="flex-row  nav-content">
      <div>
        <img src="../assets/logo.png" alt="" class="logo pointer" @click="go('/')">
      </div>
      <div  class="flex-between nav-content-text">
          <div class="menu">
            <span @click="go('/')">产品</span>
            <div class="drop">
              <div class="container">
                <ul class="flex-between-wrap">
                  <li class="nav-list" v-for="(item,index) in navList" :key="index" @click="goTo(item.arguments)">
                    <div class="nav-list-title">{{item.title}}</div>
                    <div class="nav-list-description">{{item.description}}</div>
                    <div class="nav-list-content"  :style="{'background-image': `url(${item.images})`}">
                      <div class="nav-list-type">{{item.type}}</div>
                      <div  @click="goTo(item.arguments)" class="a_green nav-list-button">了解更多 ></div>
                    </div>
                  </li>
                  <li class="nav-more pointer" @click="go('/SeriesDetails')"></li>
                </ul>
              </div>
            </div>
          </div>

        <div class="menu">
          <span @click="go('/ServiceSupport')">服务与支持</span>
          <div class="drop">
              <div  id="container-nav-mobile">
                <ul class="nav-service">
                  <li>
                    <div @click="go('/ServiceSupport')" class="a_green">服务支持</div>
                    <div @click="go('/ServiceSupport')" class="a_green">服务理念</div>
                    <div @click="go('/ServiceSupport')" class="a_green">服务宗旨</div>
                  </li>
                  <li>
                    <div @click="go('/ServiceSupport')" class="a_green">专业服务</div>
                    <div @click="go('/ServiceSupport')" class="a_green">服务理念</div>
                  </li>
                  <li>
                    <div>更多服务</div>
                    <div @click="go('/technology')" class="a_green">技术服务</div>
                    <div @click="go('/technology')" class="a_green">状态服务</div>
                  </li>
                  <li>
                    <div>售后服务</div>
                    <div @click="go('/AfterSupport')" class="a_green">质量标准</div>
                    <div @click="go('/AfterSupport')" class="a_green">保修</div>
                    <div @click="go('/AfterSupport')" class="a_green">违约职责</div>
                  </li>
                  <li>
                    <div>增值服务</div>
                    <div @click="go('/ValueAdded')" class="a_green">等保2.0</div>
                    <div @click="go('/ValueAdded')" class="a_green">热线电话</div>
                    <div @click="go('/ValueAdded')" class="a_green">服务器租用</div>
                  </li>
                  <li>
                    <div>常见问题</div>
                    <div @click="go('/Questions')" class="a_green">支持哪些设备接入？</div>
                    <div @click="go('/Questions')" class="a_green">能不能把动环数据接入到采集我们企业自己的平台？</div>
                    <div @click="go('/Questions')" class="a_green">你们的主机能接入视频吗，想了解视频接入的相关数据？</div>
                    <div @click="go('/Questions')" class="a_green">安装你们的系统设备后，访问管理平台软件，我们自己的电脑还需要配置软件吗？</div>
                    <div @click="go('/Questions')" class="a_green">系统软件支不支持远程访问监控？</div>
                    <div @click="go('/Questions')" class="a_green">你们设备都是用什么连接的？</div>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="menu">
          <span @click="go('/AboutUs')">关于我们</span>
          <!-- <div  class="drop">
              <div class="container">3</div>
            </div> -->
        </div>
      </div>
    </div>
</template>
<script>
// @ is an alias to /src
　import { reactive, onMounted,toRefs ,ref,nextTick, computed ,inject,watch } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router';
export default {
  name: 'Home',
  components: {
    navBar,
    Footer
  },

  setup() { 
    const router = useRouter()
    const reload = inject('reload')
    const useData = reactive({
        navList: [
          {
              images: require('../assets/images/ad/nav-list.png'),
              arguments: "ad",
              title: "SUMGKEE AD 系列",
              description: "自动部署",
              type:"SUMGKEE AD"
              
          },
          {
              images: require('../assets/images/v8/nav-list.png'),
              arguments: "v8",
              title: "SUMGKEE V8 系列",
              description: "自动部署",
              type:"SUMGKEE V8"
        },
        {
              images: require('../assets/images/v6/nav-list.png'),
              arguments: "v6",
              title: "SUMGKEE V6 系列",
              description: "自动部署",
              type:"SUMGKEE V6 "
        },
        {
              images: require('../assets/images/v4/nav-list.png'),
              arguments: "v4",
              title: "SUMGKEE V4 系列",
              description: "自动部署",
              type:"SUMGKEE V4 "
        },
        {
              images: require('../assets/images/v2/nav-list.png'),
              arguments: "v2",
              title: "SUMGKEE V2 系列",
              description: "自动部署",
              type:"SUMGKEE V2 "
          },
        ]
    })
    watch(() => router.currentRoute.value,() => {
        reload()
      }); 
    const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      reload()
    };
    const go = (path) => { 
      router.push({ path: path })
      reload()
    }
  
  return {
      ...toRefs(useData),
    goTo,
    go
  }
}
}
</script>
<style scoped>
.container{
  margin: 0 auto;
}
.nav-list{
  margin-bottom: 30px;
  cursor: pointer;
}
.nav-list-title{
  font-weight: bold;font-size: 22px;padding: 40px 0px 10px;color: #333333;
}
.nav-list-description{
  color: #333333;padding-bottom: 20px;
}
.nav-list-content{
  width:210px ;height: 232px;
  background-repeat: no-repeat;
background-size: cover;
text-align: center;
background-position: center;
}
.nav-list-type{
  color: #666666;font-size: 14px;padding:160px 0px 10px ;
}
.nav-more{
  background: url("../assets/images/more.png");
  width:210px ;height: 232px;
  background-repeat: no-repeat;
background-size: cover;
}
.nav-service li{
  padding: 0 40px;
  float: left;
  border-right: 1px solid #eee;
  margin: 40px 0px;
  opacity: 0.6;
  color: #000000;
}
.nav-service li div:first-child{
  font-size: 20px;
  padding-bottom: 40px;

}
.nav-service li div{
  padding-bottom: 10px;
}
.nav-service li:last-child{
  width: 250px;
 border: 0px;
}
.nav-list-button{
  color:#333;
}
#container-nav-mobile{
      width: 80rem;
      margin: 0 auto;
    }
</style>